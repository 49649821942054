import fetchWrapper from '../utils/fetch/fetchWrapper';

const fetchVehicleIncentivesWithHandle = async (params) => {
  // Remove null params
  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  );

  let url = new URL(
    `${process.env.REACT_APP_API_HOST}/vehicles/${params['vehicle_handle']}`
  );

  delete params['vehicle_handle'];
  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const json = await fetchWrapper(url, {
    method: 'GET',
  });
  const incentives = json.vehicle.incentives;
  return incentives;
};

export default fetchVehicleIncentivesWithHandle;
