import React from 'react';
import PropTypes from 'prop-types';
import { Link} from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import IncentiveCatalog from '../../../../components/IncentiveCatalog/IncentiveCatalog';
import { FormattedMessage } from 'react-intl';

const HomepageIncentives = ({ incentives }) => {
  return (
    <section className="homepage-incentives">
      <div className="homepage-incentives-title-container">
        <h2 className="homepage-incentives-title">
          <FormattedMessage
            id="homepage.incentives.title"
            defaultMessage="Maximize potential EV incentives and tax credits."
            description="Homepage Incentives Title"
          />
        </h2>
        <p className="homepage-incentives-title-sub">
          <FormattedMessage
            id="homepage.incentives.subTitle"
            defaultMessage="See how much you could save getting behind the wheel of an EV,
                whether you are buying or leasing. {lineBreak} Incentives are personalized for
                where you live."
            description="Homepage Incentives Sub Title"
            values={{
              lineBreak: <br />,
            }}
          />
        </p>
        <div className="homepage-home-charger-get" style={{ width: '100%' }}>
          <Link to="/incentives">
            <button
              className="btn btn-pseg"
              style={{ width: '245px', height: '54px' }}
            >
              <FormattedMessage
                id="personalizeIncentives"
                defaultMessage="Personalize Incentives"
                description="Personalize Incentives"
              />
            </button>
          </Link>
        </div>
      </div>
      {incentives ? (
    <IncentiveCatalog
        incentives={incentives}
        carousel={true}
        category="all"
      />
  ) : (
    <LoadingSpinner />
  )}
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array,
};
