import UsedVehicle from '../utils/Helpers/UsedVehicle';
import fetchWrapper from '../utils/fetch/fetchWrapper';

const fetchElectricVehicles = async (params) => {
  // Remove null params
  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  );

  let url = new URL(`${process.env.REACT_APP_API_HOST}/vehicles`);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  url.search += `&include_used_vehicles`;

  const json = await fetchWrapper(url, {
    method: 'GET',
  });
  const electricVehicles = json.vehicles;

  const newElectricVehicles = [];
  let usedElectricVehicles = [];

  electricVehicles.forEach((ev) => {
    if (ev.form_factor === 'Station Wagon') {
      ev.form_factor = 'wagon';
    }
    ev.images.forEach((image) => {
      if (image.legal_info_required === 1) {
        image.legal_info = ev.legal_info;
      }
    });

    if (ev.form_factor === 'pickup_truck') {
      ev.form_factor = 'Truck';
    }

    if (ev.available_for_purchase_as_new) {
      newElectricVehicles.push(ev);
    }
    usedElectricVehicles.push(UsedVehicle({ ...ev }));
  });

  // Removes any vehicles that have an electric range of NaN
  // usedElectricVehicles = usedElectricVehicles.filter( uev => !Number.isNaN(uev.electric_range))

  return {
    newElectricVehicles: newElectricVehicles,
    usedElectricVehicles: usedElectricVehicles,
  };
};

export default fetchElectricVehicles;
