import fetchWrapper from '../utils/fetch/fetchWrapper';

const fetchEvents = async (params) => {
  // Remove null params
  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  );

  let url = new URL(`${process.env.REACT_APP_API_HOST}/events`);

  if (params['distance'] === 'Nationwide') {
    url.href += '/all';
    delete params['distance'];
    delete params['postcode'];
  }

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const json = await fetchWrapper(url, {
    method: 'GET',
  });
  const events = json.events;

  return events;
};

export default fetchEvents;
