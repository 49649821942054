import React from 'react';
import './Footer.scss';
import logo from '../../../assets/images/dominionLogo.svg';
import face from '../../../assets/images/icons/Facebook.svg';
import linkedin from '../../../assets/images/icons/Linkedin.svg';
import twitter from '../../../assets/images/icons/Twitter.svg';
import zappy from '../../../assets/images/icons/zappy-icon.svg';

const Footer = () => {
  const mobile = window.innerWidth < 911;

  return (
    <footer className="Footer">
      <div className="container">
        <div className="social-container">
          <img src={face} alt="Dominion Facebook" />
          <img src={twitter} alt="Dominion Twitter" />
          <img src={linkedin} alt="Dominion Linkedin" />
        </div>
        {!mobile ? (
          <>
            <div className="text-container">
              <p className="mb-0">
                For more information about EVs and charging, email us at{' '}
                <a href="mailto:electrification@dominionenergy.com">
                  electrification@dominionenergy.com
                </a>
              </p>
            </div>
            <div className="disclaimer-container">
              <p>
                Copyright © 2024 Dominion Energy. All rights reserved. EV
                solutions provided by
              </p>
              <img src={zappy} alt="Zappyride J.D. Power" />
              <p>, a J.D. Power business.</p>
            </div>
          </>
        ) : (
          <div className="mobile-disclaimer-container">
            <p>Copyright © 2024 Dominion Energy. All rights reserved.</p>
            <div className="zappy-wrapper">
              <p>EV solutions provided by</p>
              <img src={zappy} alt="Zappyride J.D. Power" />
              <p>, a J.D. Power business.</p>
            </div>
          </div>
        )}
        {!mobile && (
          <div className="logo-container">
            <img src={logo} alt="Dominion Energy" />
          </div>
        )}
        <div className="text-container">
          <p>
            Figures shown on this page are estimates based on data provided by
            third-parties. This material is provided for informational purposes
            only and should not be relied on for tax, legal, or financial
            advice. Neither J.D. Power® nor Dominion Energy provides tax, legal
            or financial advice. You should independently verify the estimates
            provided here before engaging in any transaction.
          </p>
        </div>

        {mobile && (
          <div className="logo-container">
            <img src={logo} alt="Dominion Energy" />
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
