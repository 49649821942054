import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import logo from '../../../assets/images/dominionLogo.svg';
import './Header.scss';
import { FormattedMessage } from 'react-intl';

const Header = ({
  page,
  zipcode,
  activePage,
  userLocation,
  language,
  changeLanguage,
}) => {
  const [collapse, setCollapse] = useState(false);
  const [evDropdown, setEvDropdown] = useState(false);
  const [moreDropdown, setMoreDropdown] = useState(false);

  const isHomepage =
    window.location.pathname === '/' || window.location.pathname === null;

  const mobile = window.innerWidth < 720;

  return (
    <header className="Header">
      <div className="container-fluid" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div
            className="logo"
            style={{
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
            }}
          >
            <Link to="/" className={isHomepage ? 'active' : ''}>
              <img
                src={logo}
                className="homepagelogo"
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
              />
            </Link>
          </div>
          <NavbarToggler
            onClick={() => setCollapse(!collapse)}
            aria-label="Toggle Dropdown"
          />
          <Collapse isOpen={collapse} navbar>
            <Nav navbar>
              <NavItem>
                <Link to="/" className={activePage === '' ? 'active' : ''}>
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.home"
                      defaultMessage="Home"
                      description="Home page"
                    />
                  </span>
                </Link>
              </NavItem>
              <li><div className="divisor" /></li>
              {mobile ? (
                <>
                  <NavItem>
                    <Link to="/vehicles">
                      <span className="mobile-header-item">
                        <FormattedMessage
                          id="header.electricVehicles.browse"
                          defaultMessage="Browse Models"
                        />
                      </span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/compare-vehicles">
                      <span className="mobile-header-item">
                        <FormattedMessage
                          id="header.electricVehicles.compare"
                          defaultMessage="Browse Models"
                        />
                      </span>
                    </Link>
                  </NavItem>
                </>
              ) : (
                <NavItem>
                  <div
                    className="dropdown-container"
                    onClick={() => setEvDropdown((e) => !e)}
                  >
                    <span className="mobile-header-item">
                      <FormattedMessage
                        id="header.electricVehicles"
                        defaultMessage="Browse EVs"
                        description="Shop Electric Header"
                      />
                    </span>
                    {evDropdown && (
                      <div className="dropdown-wrapper">
                        <Link to="/vehicles">
                          <span className="mobile-header-item">
                            <FormattedMessage
                              id="header.electricVehicles.browse"
                              defaultMessage="Browse Models"
                            />
                          </span>
                        </Link>

                        <Link to="/compare-vehicles">
                          <span className="mobile-header-item">
                            <FormattedMessage
                              id="header.electricVehicles.compare"
                              defaultMessage="Browse Models"
                            />
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                </NavItem>
              )}
              <li><div className="divisor" /></li>
              <NavItem>
                <NavLink
                  title={page === 'incentives' ? 'Active Page' : null}
                  to="/incentives"
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.incentives"
                      defaultMessage="Incentives"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <li><div className="divisor" /></li>
              {/* <NavItem>
                <NavLink
                  title={page === 'rates' ? 'Active Page' : null}
                  to="/rates"
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.rates"
                      defaultMessage="Compare Rates"
                      description="Rates Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <li><div className="divisor" /></li> */}

              <NavItem>
                <NavLink
                  title={page === 'charging' ? 'Active Page' : null}
                  to="/charging-stations"
                >
                  <span className="mobile-header-item">
                    <FormattedMessage
                      id="header.chargingStations"
                      defaultMessage="Charging Stations"
                      description="Charging Stations Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <li><div className="divisor" /></li>

              {mobile ? (
                <>
                  <NavItem>
                    <Link to="/ev-101">
                      <span className="mobile-header-item">
                        <FormattedMessage
                          id="header.learnMore.101"
                          defaultMessage="Browse Models"
                        />
                      </span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/emissions">
                      <span className="mobile-header-item">
                        <FormattedMessage
                          id="header.learnMore.emissions"
                          defaultMessage="Browse Models"
                        />
                      </span>
                    </Link>
                  </NavItem>
                </>
              ) : (
                <NavItem>
                  <div
                    className="dropdown-container"
                    onClick={() => setMoreDropdown((e) => !e)}
                  >
                    <span className="mobile-header-item">
                      <FormattedMessage
                        id="header.learnMore"
                        defaultMessage="Browse EVs"
                        description="Shop Electric Header"
                      />
                    </span>
                    {moreDropdown && (
                      <div className="dropdown-wrapper learn-more">
                        <Link to="/ev-101">
                          <span className="mobile-header-item">
                            <FormattedMessage
                              id="header.learnMore.101"
                              defaultMessage="Browse Models"
                            />
                          </span>
                        </Link>

                        <Link to="/emissions">
                          <span className="mobile-header-item">
                            <FormattedMessage
                              id="header.learnMore.emissions"
                              defaultMessage="Browse Models"
                            />
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
