import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from '../../../components/shared/InputElements/Select';

const RegionsSelector = ({ region = 'northCarolina', setRegion }) => {
  // we are using region name only for don't confuse with context state
  const intl = useIntl();

  const regions = useMemo(
    () => [
      {
        id: 'northCarolina',
        value: intl.formatMessage({
          id: 'states.northCarolina',
          defaultMessage: 'North Carolina',
        }),
      },
      {
        id: 'virginia',
        value: intl.formatMessage({
          id: 'states.virginia',
          defaultMessage: 'Virginia',
        }),
      },
    ],
    [intl]
  );

  return (
    <>
      <Select
        id="emissions-power-mix-id"
        value={region}
        handler={(e) => setRegion(e.target.value)}
        optionNames={regions.map((region) => region.value)}
        optionValues={regions.map((region) => region.id)}
        label={
          <FormattedMessage
            id="emission.select.state.label"
            defaultMessage="Select a state"
            description="Select a state"
          />
        }
      />
    </>
  );
};

RegionsSelector.propTypes = {
  setRegion: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
};

export default RegionsSelector;
