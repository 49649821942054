import fetchWrapper from '../utils/fetch/fetchWrapper';

const fetchGasolineVehicles = async (params) => {
  // Remove null params
  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  );

  let url = new URL(`${process.env.REACT_APP_API_HOST}/vehicles`);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const json = await fetchWrapper(url, {
    method: 'GET',
  });
  const gasolineVehicles = json.vehicles;

  gasolineVehicles.forEach((gv) => {
    gv.images.forEach((image) => {
      if (image.legal_info_required === 1) {
        image.legal_info = gv.legal_info;
      }
    });
  });
  return gasolineVehicles;
};

export default fetchGasolineVehicles;
