import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './Homepage.scss';
import HomepageBigPromise from './components/HomepageBigPromise';
import HomepageIncentives from './components/HomepageIncentives';
import getLocalVehicles from '../../../functions/vehicle/getLocalVehicles';
import ShuffleFlatArray from '../../../utils/Helpers/ShuffleFlatArray';
import VehicleCarousel from '../VehicleCarousel/VehicleCarousel';
import MapPage from '../../pages/MapPage/MapPage';
import { vanityUrls } from '../../data/vanityUrls/vanityUrls';
import ImpactChartSection from '../ImpactChartSection/ImpactChartSection';


const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  userLocation,
  dealerLocations,
  usedElectricVehicles,
  history,
  tabId,
  ip,
  uuid,
  title,
  faqs,
  ...props
}) => {

  const [vehicles, setVehicles] = useState([]);
  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  };
  const url = props.location
    ? (props.location.pathname + props.location.search).toLowerCase()
    : null;

  useEffect(() => {
    document.title = title;
    setElectricVehicles();
  });

  useEffect(() => {
    if (vanityUrls[url]) {
      props.history.push(vanityUrls[url]);
    }
  }, [props, url]);

  useEffect(() => {
    const script = document.createElement('script');
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      script.setAttribute('type', 'text/javascript');
      script.text =
        "gtag('event', 'conversion', {'allow_custom_scripts': true,'send_to': 'DC-9208024/ev_pa0/ev_ho0+unique'});";
      document.body.appendChild(script);
    }

    return () => {
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      <HomepageBigPromise />
      <VehicleCarousel
        electricVehicles={getLocalVehicles(vehicles)}
        userLocation={userLocation}
      />
      <HomepageIncentives incentives={incentives} />
      <MapPage
        userLocation={userLocation}
        tabId={tabId}
        electricVehicles={electricVehicles}
        dealerLocations={dealerLocations}
        homePage={true}
      />
      <ImpactChartSection/>
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
