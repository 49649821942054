export const getBarChartData = (intl, isNorthCarolina) => {
  return {
    labels: [
      intl.formatMessage({
        id: 'emissions.barChart.label.gas',
        defaultMessage: 'Gas',
      }),
      intl.formatMessage({
        id: 'emissions.barChart.label.evUSA',
        defaultMessage: 'EV in USA',
      }),
      intl.formatMessage({
        id: `emissions.barChart.label.${isNorthCarolina ? 'evNC' : 'evVA'}`,
      }),
    ],
    datasets: [
      {
        label: '#',
        backgroundColor: ['#768692', '#8BB8E8', '#009639'],
        data: [439, 157, 51],
      },
    ],
  };
};

export const getDoughnutChartData = (intl) => {
  const DOUGHNUTVALUES = {
    nuclear: 29.2,
    naturalGas: 36,
    coal: 5,
    biomass: 1.1,
    hydro: 0.5,
    solar: 1,
    pumpedStorageBattery: 2.5,
    renewablePurchases: 2,
    marketPurchases: 22.7,
  };

  return {
    labels: [
      intl.formatMessage(
        {
          id: 'emissions.doughnut.nuclear',
          defaultMessage: 'Nuclear {value}%',
        },
        { value: DOUGHNUTVALUES.nuclear }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.naturalGas',
          defaultMessage: 'Natural Gas {value}%',
        },
        { value: DOUGHNUTVALUES.naturalGas }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.coal',
          defaultMessage: 'Coal {value}%',
        },
        { value: DOUGHNUTVALUES.coal }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.biomass',
          defaultMessage: 'Biomass {value}%',
        },
        { value: DOUGHNUTVALUES.biomass }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.hydro',
          defaultMessage: 'Hydro {value}%',
        },
        { value: DOUGHNUTVALUES.hydro }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.solar',
          defaultMessage: 'Solar {value}%',
        },
        { value: DOUGHNUTVALUES.solar }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.pumpedStorageBattery',
          defaultMessage: 'Pumped Storage/Battery {value}%',
        },
        { value: DOUGHNUTVALUES.pumpedStorageBattery }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.renewablePurchases',
          defaultMessage: 'Renewable Purchases PPA {value}%',
        },
        { value: DOUGHNUTVALUES.renewablePurchases }
      ),
      intl.formatMessage(
        {
          id: 'emissions.doughnut.marketPurchases',
          defaultMessage: 'Market Purchases {value}%',
        },
        { value: DOUGHNUTVALUES.marketPurchases }
      ),
    ],
    datasets: [
      {
        label: '#',
        backgroundColor: [
          '#1D4F91',
          '#EF8700',
          '#8BB8E8',
          '#AF95A6',
          '#768692',
          '#009639',
          '#7C7C7C',
          '#C4D600',
          '#B3B3B3',
        ],
        data: [
          DOUGHNUTVALUES.nuclear,
          DOUGHNUTVALUES.naturalGas,
          DOUGHNUTVALUES.coal,
          DOUGHNUTVALUES.biomass,
          DOUGHNUTVALUES.hydro,
          DOUGHNUTVALUES.solar,
          DOUGHNUTVALUES.pumpedStorageBattery,
          DOUGHNUTVALUES.renewablePurchases,
          DOUGHNUTVALUES.marketPurchases,
        ],
      },
    ],
  };
};

