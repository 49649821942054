import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import InputWorkingZipcodeForChargingMap from '../../../../components/InputComponents/InputZipcode/InputZipcodeForChargingMap';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

import '../../../../components/LocationMap/LocationMap.scss';
import { FormattedMessage } from 'react-intl';
import useChargingStations from '../../../../hooks/useChargingStations';
import filterWithinBounds from '../../../../utils/Helpers/filterWithinBounds';
import BaseGoogleMapReact from '../../../../components/LocationMap/BaseGoogleMapReact/BaseGoogleMapReact';
import useMappedClusters from '../../../../hooks/useMappedClusters';
import ChargingStationsMapMarker from '../../../../components/LocationMap/ChargingStationsMapMarker/ChargingStationsMapMarker';
import ChargingStationClusterMarker from '../../../../components/LocationMap/ChargingStationClusterMarker/ChargingStationClusterMarker';
import isSuperChargingStation from '../../../../utils/predicates/isSuperChargingStation';
import useSelectedStation from '../../../../hooks/useSelectedStation';
import MapControlPanel from '../../../../components/LocationMap/MapControlPanel/MapControlPanel';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

import './ChargingMap.scss';
const DEFAULT_MAP_ZIPCODE = 23219;
const ChargingMap = ({
  userLocation,
  ignoreSuperchargerStations,
  isVisible = true,
  toggleWithHistory,
  isPage,
  additionalFetch,
  additionalBounds,
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const [bounds, setBounds] = useState({
    nw: undefined,
    ne: undefined,
    sw: undefined,
    se: undefined,
  });

  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(10);
  const [hasRezoomed, setHasRezoomed] = useState(false);
  const mapRef = useRef();

  useEffect(() => {
    if (!userLocation) return;

    const defaultZoom = userLocation.region === 'New Mexico' ? 9.5 : 10;

    setTimeout(() => {
      if (!hasRezoomed) {
        setZoom(defaultZoom);
        setHasRezoomed(true);
      }
    }, 0);

    setCenter({
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude),
    });
  }, [userLocation, zoom, hasRezoomed]);

  const { chargingStations, fetchChargingStations } = useChargingStations();
  const [selectedStation, selectStation, deselectStations] =
    useSelectedStation(chargingStations);
  const visibleChargingStations = filterWithinBounds(chargingStations, bounds);
  const filteredChargingStations = ignoreSuperchargerStations
    ? visibleChargingStations.filter(
        (station) => !isSuperChargingStation(station)
      )
    : visibleChargingStations;

  const powerFilteredChargingStations =
    userPrefs.get('vehicleTypeFilter') === 'All'
      ? filteredChargingStations
      : filteredChargingStations.filter((station) =>
          userPrefs.get('vehicleTypeFilter') === 'highPower'
            ? Number(station.ev_dc_fast_num)
            : userPrefs.get('vehicleTypeFilter') === 'public'
            ? station.access_code === 'public'
            : station.access_code !== 'public'
        );

  const { clusteredPoints, individualPoints, supercluster } = useMappedClusters(
    {
      data: powerFilteredChargingStations,
      bounds,
      zoom,
    }
  );

  const onMapChange = ({
    center: newCenter,
    bounds: newBounds,
    zoom: newZoom,
  }) => {
    setBounds(newBounds);
    additionalBounds(newBounds);
    setZoom(newZoom);
    const { lat: latitude, lng: longitude } = newCenter;

    fetchChargingStations({ latitude, longitude }, newBounds);
    additionalFetch({ latitude, longitude }, newBounds);
  };

  const saveMapRefs = ({ map }) => {
    mapRef.current = map;
  };
  return (
    <div className="ChargingMap">
      <div className="row hide-offscreen">
        <div className="col-sm-12 text-center">
          <h2>
            <FormattedMessage
              id="chargingStations"
              defaultMessage="Charging Stations"
              description="Charging Stations"
            />
          </h2>
        </div>
      </div>
      <div className="row input-working-zipcode">
        <InputWorkingZipcodeForChargingMap zipcode={DEFAULT_MAP_ZIPCODE} />
      </div>
      <div className="row map-wrapper">
        <div className="map-container-wrapper">
          {!userLocation && (
            <div className="spinner-container">
              <LoadingSpinner />
            </div>
          )}
          {userLocation && (
            <>
              <div className="map-container">
                {isVisible && (
                  <BaseGoogleMapReact
                    center={center}
                    zoom={zoom}
                    onChange={onMapChange}
                    onGoogleApiLoaded={saveMapRefs}
                  >
                    {individualPoints.map((point) => (
                      <ChargingStationsMapMarker
                        key={point.properties.data.id}
                        station={point.properties.data}
                        lat={point.geometry.coordinates[1]}
                        lng={point.geometry.coordinates[0]}
                        onMouseOver={() => {
                          selectStation(point.properties.data.id);
                        }}
                        onClick={deselectStations}
                        isSelected={
                          point.properties.data.id === selectedStation?.id
                        }
                      />
                    ))}
                    {clusteredPoints.map((cluster) => (
                      <ChargingStationClusterMarker
                        key={cluster.id}
                        cluster={cluster}
                        supercluster={supercluster}
                        map={mapRef.current}
                        lat={cluster.geometry.coordinates[1]}
                        lng={cluster.geometry.coordinates[0]}
                      />
                    ))}
                  </BaseGoogleMapReact>
                )}
              </div>
              <div className="disclaimer-container">
                <p className="charging-map-legal-disclaimer legal-disclaimer">
                  <FormattedMessage
                    id="disclaimer.chargingMap"
                    defaultMessage="Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {link}."
                    values={{
                      link: (
                        <a
                          href="https://afdc.energy.gov/stations/#/station/new"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                      ),
                    }}
                  />
                </p>
              </div>
            </>
          )}
        </div>
        <div
          className="map-control-wrapper"
          style={{ width: 'auto', padding: '0 0 0 38px' }}
        >
          <MapControlPanel
            selectedStation={selectedStation}
            chargingStations={powerFilteredChargingStations}
            toggleWithHistory={toggleWithHistory}
          ></MapControlPanel>
        </div>
      </div>
    </div>
  );
};

export default ChargingMap;

ChargingMap.propTypes = {
  userLocation: PropTypes.object,
  ignoreSuperchargerStations: PropTypes.bool,
};
