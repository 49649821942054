import React, { useMemo, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import './Emissions.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import RegionsSelector from '../../components/RegionsSelector/RegionsSelector';
import {
  getBarChartData,
  getDoughnutChartData,
} from '../../../utils/emissions';

const CHART_JS_OPTIONS = (isNorthCarolina) => ({
  maintainAspectRatio: false,
  tooltips: {
    enabled: false,
  },
  scales: {
    xAxes: [
      {
        barThickness: 75,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 500,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: (context) => {
        return context.datasetIndex === 0;
      },
      formatter: (value, context) => {
        if (context.datasetIndex === 0) {
          if (value === 439)
            return isNorthCarolina ? '12,594 lbs' : '12,595 lbs';
          if (value === 157) return '2,727 lbs';
          if (value === 51) return isNorthCarolina ? '2,169 lbs' : '2,059 lbs';
        }
        return '';
      },
      align: 'end',
      anchor: 'end',
      color: '#000',
      font: {
        weight: 400,
        size: 16,
      },
      rotation: -90,
    },
  },
});

const CHART_JS_OPTIONS_MOBILE = (isNorthCarolina) => ({
  maintainAspectRatio: false,
  tooltips: {
    enabled: false,
  },
  scales: {
    xAxes: [
      {
        barThickness: 75,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 500,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: (context) => {
        return context.datasetIndex === 0;
      },
      formatter: (value, context) => {
        if (context.datasetIndex === 0) {
          if (value === 439)
            return isNorthCarolina ? '12,594 lbs' : '12,595 lbs';
          if (value === 157) return '2,727 lbs';
          if (value === 51) return isNorthCarolina ? '2,169 lbs' : '2,059 lbs';
        }
        return '';
      },
      align: 'end',
      anchor: 'end',
      color: '#000',
      font: {
        weight: 400,
        size: 16,
      },
      rotation: 0,
    },
  },
});

const Doughnut_CHART_JS_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    enabled: false,
  },
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      boxWidth: 20,
      boxHeight: 20,
      fontColor: '#2B2B2B',
      fontSize: 18,
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif',
    },
  },
  cutoutPercentage: 30,
  plugins: {
    datalabels: {
      display: () => {
        return '';
      },
      formatter: () => {
        return '';
      },
    },
  },
};

const Emissions = () => {
  // we are using region name only for don't confuse with context state
  const [region, setRegion] = useState('northCarolina');
  const intl = useIntl();

  const isNorthCarolina = region === 'northCarolina';

  const BarChartData = useMemo(
    () => getBarChartData(intl, isNorthCarolina),
    [isNorthCarolina, intl]
  );

  const DoughnutChartData = useMemo(() => getDoughnutChartData(intl), [intl]);

  return (
    <section className="page-wrapper">
      <div className="page-container">
        <div className="text-container">
          <h1>
            <FormattedMessage
              id="emissions.title"
              defaultMessage="Emissions & Power Mix"
            />
          </h1>
          <h2>
            <FormattedMessage
              id="emissions.subtitle"
              defaultMessage="{state}’s power mix means you can dramatically reduce your CO2 emissions by switching to an electric vehicle."
              values={{
                state: intl.formatMessage({
                  id: `states.${region}`,
                }),
              }}
            />
          </h2>
        </div>

        <div className="emissions-wrapper">
          <div className="emissions-filters-container">
            <p>
              <FormattedMessage
                id="emissions.filter"
                defaultMessage="Emissions Filters"
              />
            </p>
            <RegionsSelector region={region} setRegion={setRegion} />
          </div>
          <div className="chart-wrapper">
            <div className="chart-container big">
              <p>
                <FormattedMessage
                  id="emissions.chart.barTitle"
                  defaultMessage="Average CO2 Emissions per Mile"
                />
              </p>
              <div className="average-container">
                <div class="bar-container desktop">
                  <Bar
                    data={BarChartData}
                    options={CHART_JS_OPTIONS(isNorthCarolina)}
                    type="verticalBar"
                  />
                </div>
                <div class="bar-container mobile">
                  <Bar
                    data={BarChartData}
                    options={CHART_JS_OPTIONS_MOBILE(isNorthCarolina)}
                    type="verticalBar"
                  />
                </div>
              </div>
            </div>
            <p>
              <FormattedMessage
                id="emissions.chart.disclaimer"
                defaultMessage="*As of 2022. Data Courtesy of the AFDC."
              />
            </p>

            <div className="chart-container">
              <p>
                <FormattedMessage
                  id="emissions.chart.bar-title"
                  defaultMessage="Dominion Energy {state} Power Mix"
                  values={{
                    state: intl.formatMessage({
                      id: `states.${region}`,
                    }),
                  }}
                />
              </p>
              <div className="power-mix-container">
                <Doughnut
                  data={DoughnutChartData}
                  options={Doughnut_CHART_JS_OPTIONS}
                />
              </div>
            </div>

            <p>
              <FormattedMessage
                id="emissions.disclaimer"
                defaultMessage="*As of 2023. Data Courtesy of Dominion Energy Integrated Resource Plan. {br} *Market Purchases include PJM purchases which cannot disaggregated into specific resource types."
                values={{
                  br: <br />,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Emissions;
