import React, { useMemo, useState } from 'react';
import './ImpactChartSection.scss';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { FormattedMessage, useIntl } from 'react-intl';
import RegionsSelector from '../RegionsSelector/RegionsSelector';
import {
  getBarChartData,
  getDoughnutChartData,
} from '../../../utils/emissions';

const ImpactChartSection = () => {
  const mobile = window.innerWidth < 911;
  const intl = useIntl();
  const [region, setRegion] = useState('northCarolina');

  const isNorthCarolina = region === 'northCarolina';

  const BarChartData = useMemo(
    () => getBarChartData(intl, isNorthCarolina),
    [isNorthCarolina, intl]
  );

  const CHART_JS_OPTIONS = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    scales: {
      xAxes: [
        {
          barThickness: 75,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 500,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: (context) => {
          return context.datasetIndex === 0;
        },
        formatter: (value, context) => {
          if (context.datasetIndex === 0) {
            if (value === 439)
              return isNorthCarolina ? '12,594 lbs' : '12,595 lbs';
            if (value === 157) return '2,727 lbs';
            if (value === 51)
              return isNorthCarolina ? '2,169 lbs' : '2,059 lbs';
          }
          return '';
        },
        align: 'end',
        anchor: 'end',
        color: '#000',
        font: {
          weight: 400,
          size: 16,
        },
      },
    },
  };

  const DoughnutChartData = useMemo(() => getDoughnutChartData(intl), [intl]);

  const Doughnut_CHART_JS_OPTIONS = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: true,
      position: mobile ? 'bottom' : 'right',
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        fontColor: '#2B2B2B',
        fontSize: 18,
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif',
      },
    },
    cutoutPercentage: 30,
    plugins: {
      datalabels: {
        display: (context) => {
          return '';
        },
        formatter: (value, context) => {
          return '';
        },
      },
    },
  };

  return (
    <section className="impact-wrapper">
      <div className="impact-container">
        <div className="top-container">
          <h1>
            <FormattedMessage
              id="homepage.impact.title"
              defaultMessage="Make an impact every time you recharge."
            />
          </h1>
          <h2>
            <FormattedMessage
              id="homepage.impact.subtitle"
              defaultMessage="{state}’s power mix means you can dramatically reduce your CO2 emissions by switching to an electric vehicle."
              values={{
                state: intl.formatMessage({
                  id: `states.${region}`,
                }),
              }}
            />
          </h2>

          <div className="btn-container">
            <RegionsSelector region={region} setRegion={setRegion} />
          </div>
        </div>

        <div className="bottom-container">
          <div className="bar-chart-wrapper">
            <div className="bar-container">
              <Bar
                data={BarChartData}
                options={CHART_JS_OPTIONS}
                type="verticalBar"
              />
            </div>
            <p>
              <FormattedMessage
                id="homepage.chart.bar-title"
                defaultMessage="Average CO2 Emissions per Mile"
              />
            </p>
            {!mobile && (
              <span>
                <FormattedMessage
                  id="homepage.chart.bar-title"
                  defaultMessage="*As of 2022. Data Courtesy of the AFDC"
                />
              </span>
            )}
          </div>

          <div className="Doughnut-chart-wrapper">
            <div className="Doughnut-container">
              <Doughnut
                data={DoughnutChartData}
                options={Doughnut_CHART_JS_OPTIONS}
                type="verticalBar"
              />
            </div>

            <div className="text-container">
              <p>
                <FormattedMessage
                  id="emissions.chart.bar-title"
                  defaultMessage="Dominion Energy {state} Power Mix"
                  values={{
                    state: intl.formatMessage({
                      id: `states.${region}`,
                    }),
                  }}
                />
              </p>
            </div>
            <span>
              <FormattedMessage
                id="emissions.disclaimer"
                defaultMessage="*As of 2023. Data Courtesy of Dominion Energy Integrated Resource Plan. {br} *Market Purchases include PJM purchases which cannot disaggregated into specific resource types."
                values={{
                  br: <br />,
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImpactChartSection;
